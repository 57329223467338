const core = {
  name: 'Nabraj Khadka',
  education:
    {
      college: 'Islington College',
      url: 'https://islington.edu.np/',
    },
  email:
    {
      primary: 'nabrajkhadka43@gmail.com',
      secondary: 'nabrajkhadka431@gmail.com',
    },
  messages:
  [
    'nabrajkhadka43@gmail.com',
    'nabrajkhadka431@gmail.com',
  ],
};

export default core;
