const routes = [
  {
    index: true,
    label: 'Nabraj Khadka',
    path: '/',
  },
  {
    label: 'Projects',
    path: '/projects',
  },
  
  {
    label: 'Resume',
    path: '/resume',
  },
  
  { label: 'Blogs', url: 'https://nabrajkhadka.hashnode.dev/' }, 
  { label: 'Linkedin', url: 'https://www.linkedin.com/in/iamnabink/' }, 

  // {
  //   label: 'Stats',
  //   path: '/stats',
  // },
  {
    label: 'Contact',
    path: '/contact',
  },
];

export default routes;
